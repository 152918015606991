import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "permission" }
const _hoisted_2 = { class: "permission__text" }

import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import EquickPermissionPopup from "@/components/Equick/Popup/EquickPermissionPopup.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'EquickFieldPermission',
  setup(__props) {

const appStore = useAppStore();
const { isAgree } = storeToRefs(appStore);

const isPopupShown = ref(false);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(["permission__icon", {'permission__icon--active': _unref(isAgree)}]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (isAgree.value = !_unref(isAgree)))
      }, null, 2),
      _createElementVNode("p", _hoisted_2, [
        _createTextVNode(_toDisplayString(_ctx.$t('field_permission.part_1')) + " ", 1),
        _createElementVNode("span", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (isPopupShown.value = true))
        }, _toDisplayString(_ctx.$t('field_permission.part_2')), 1)
      ])
    ]),
    (isPopupShown.value)
      ? (_openBlock(), _createBlock(EquickPermissionPopup, {
          key: 0,
          onClose: _cache[2] || (_cache[2] = ($event: any) => (isPopupShown.value = false))
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})