<template>
  <teleport :to="teleport ?? (isGotovo ? '.page' : 'body')">
    <div class="popup-container" :class="$attrs.class">
      <div class="popup-area" :class="{'with-button': hasCloseButton}">
        <div v-if="hasCloseButton" class="popup__close" @click="onCloseClick"></div>
        <p v-if="message" v-html="message" class="popup__message"></p>
        <p v-if="additional" v-html="additional" class="popup__additional"></p>
        <slot></slot>
      </div>
    </div>
  </teleport>
</template>

<script lang="ts" setup>
import { useMain } from '@/composable/useMain';

defineProps({
  message: String,
  additional: String,
  hasCloseButton: {
    type: Boolean,
    default: true
  },
  teleport: {
    type: String,
    default: null
  }
});
const emits = defineEmits(['close']);

const { isGotovo } = useMain();

const onCloseClick = () => emits('close');
</script>

<style lang="scss" scoped>
.popup {
  &-container {
    @extend .full-page-container, .flex-col;
    justify-content: center;
    align-items: center;
    background-color: #00000073;
    z-index: 2000 !important;

    :deep(span.smaller) {
      display: inline-block;
      font-size: 14px;
      line-height: 120%;
      color: #999;
      text-wrap: balance;
    }

    :deep(span.smaller:first-of-type) {
      margin-top: 1.25rem;
      margin-bottom: 0.5rem;
    }
  }

  &-area {
    @extend .flex-col;
    position: relative;
    align-items: center;
    justify-content: center;
    width: max-content;
    min-width: 35vw;
    max-width: 60vw;
    height: max-content;
    min-height: 25vh;
    padding: 1.875rem;
    margin: 2rem 0;

    background-color: white;
    border-radius: 16px;

    &.with-button {
      padding-top: 3.5rem;
    }
  }

  &__close {
    @extend .pointer;
    position: absolute;
    top: 0;
    right: 0;
    width: 1.5rem * 3;
    height: 1.5rem * 3;
    @include svgBg($close, 1.5rem);
    z-index: 100;
  }

  &__message {
    @extend .font-size-header, .bolder, .text-center;
    margin-bottom: 2rem;
    line-height: 3.125rem;
    color: $light-gray;
  }

  &__additional {
    @extend .text-center, .font-size-l;
    margin-bottom: 2rem;
    color: #666;

    & + :deep(.default-button) {
      margin-top: 1rem;
    }
  }
}

.gotovo .message {
  :deep(.popup-area) {
    padding: 1rem !important;
  }

  .popup__additional {
    font-size: 1.25rem;
  }
}
</style>
