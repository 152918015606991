<template>
  <popup-container @close="$emit('close')">
    <p v-html="texts" class="texts"></p>
    <equick-base-button :text="$t('equick.submit_button')" @click="onSubmitClick" />
  </popup-container>
</template>

<script setup lang="ts">
import PopupContainer from "@/components/Popups/PopupContainer.vue";
import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";
import EquickBaseButton from "@/components/Equick/Button/EquickBaseButton.vue";
import { useTerminalStore } from "@/store/terminal.store";
import { computed, onMounted } from "vue";

const emits = defineEmits(['close']);

const appStore = useAppStore();
const { isAgree } = storeToRefs(appStore);
const terminalStore = useTerminalStore();
const { articles } = storeToRefs(terminalStore);

const texts = computed((): string => {
  return Object.values(articles.value).map(v => [v.description.name, v.description.subject].join('')).join('<br/>');
});

const onSubmitClick = () => {
  isAgree.value = true;
  emits('close');
};

onMounted(async () => {
  if (!Object.keys(articles.value).length) {
    await terminalStore.getTerminalArticles();
  }
});
</script>

<style lang="scss">
.texts {
  @include font($--20px, 120%, black, 500);
  height: 90%;
  overflow: auto;
  h5 {
    margin: 0 0 $--12px;
  }
  ul {
    padding-left: 0;
  }
  p {
    margin-bottom: $--12px;
  }
  &::-webkit-scrollbar {
    display: initial;
    width: 8px;
    background-color: #E3EFF1;
    border-radius: 50px;
  }
  &::-webkit-scrollbar-thumb {
     height: 0.5rem;

     background: $blue--tr;
     border-radius: 0.25rem;
   }
}
</style>
